export const BG_PRIMARY = '#fff';
export const BG_SECONDARY = '#7B0A1C';
export const BG_NAVIGATION = '#a35460';
export const TEXT_PRIMARY_LIGHT = `#fff`;
export const BG_SECONDARY_DARK = '#6f0919';
export const BG_SECONDARY_TRANSPARENT = 'rgb(80 6 18 / 55%)';
export const TEXT_PRIMARY = '#000';
export const TEXT_SECONDARY = BG_SECONDARY;

export const REVERSE_ELIPS_COLOR = BG_PRIMARY;

export const DEFAULT_BUTTON_PROPS = '';
